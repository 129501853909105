import { DEFAULT_LOCALE } from '@constants/locales';
import { EmptyStateComponent } from '@molecules/frontoffice/EmptyStateComponent';
import { SEO } from '@organisms/SEO';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export const getStaticProps: GetStaticProps = async ({ ...ctx }) => {
  const queryClient = new QueryClient();

  return {
    props: {
      ...(await serverSideTranslations(ctx.locale ?? DEFAULT_LOCALE, [
        'common',
      ])),
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 120,
  };
};

const _404 = () => {
  return (
    <>
      <SEO index={false} />
      <EmptyStateComponent />
    </>
  );
};

export default _404;
